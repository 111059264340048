<template>
  <div>
    <div class="warp">
      <div class="time">
        <div class="timeduan"
             v-for="time in 10"
             :key="time">
          {{item}}
        </div>
      </div>
      <div class="rigth">
        <div class="rooms">
          <div class="room"
               v-for="item in 4"
               :key="item">
            {{item}}
          </div>
          <div class="grid">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.warp {
  display: flex;
  width: 900px;
  display: flex;
  .time {
    height: 100px;
    width: 100px;
  }
  .rigth {
    flex: 1;
    .rooms {
      display: flex;
      align-items: center;
      .room {
        height: 100px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>